<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{snackbarText}}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <div>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="11" md="11">
          <h1>Eliminar menu {{longName}}</h1>
          <span style="color:#ff1744;">Esta opción te permite eliminar el menu completo de un comercio.</span>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="businessId"
            solo-inverted
            clearable
            rounded
            :loading="loading"
            flat
            hide-details
            label="Ingresar ID del comercio"
            prepend-inner-icon="fa-search"
            v-on:keyup.enter="searchBusiness"
            class="d-none d-md-flex"
          />
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn
            @click="searchBusiness"
            color="primary"
            dark
            rounded
            class="flex-grow-1 ml-2"
            large
            block
            :elevation="0"
            :minHeight="50"
            >Cargar datos
          </v-btn>
        </v-col>
      </v-row>
    </div>

 
    <ul>
        <li v-for="item in products" :key="item.id">
          {{ item.id }} -
          {{ item.data.name }}  
        </li>    
    </ul>
  
        <!-- modal delete  -->
      <v-dialog max-width="400" v-model="dialog" >
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar los datos?
        </v-card-text>

        <v-card-actions class="mt-2" style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

 

<script>
import { db  } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
 
export default {
  components: {
    lottie: Lottie
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      businessId: "",
      longName: "",
      products:[],
      dialog: false,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      fileName: null,
      file: null,
      modalBusinessData: false,
      business: null,
      modalStructureExcel: false,
    };
  },
 
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
 
    searchBusiness() {

      if (this.businessId) {
        this.loading = true;
        db.collection("businesses")
          .doc(this.businessId)
          .get()
          .then((res) => {
            this.loading = false;
     
            if (res.data()) {
              this.business = res.data();
              this.business[".key"] = res.id;
              this.longName=res.data().longName;
              this.loadProducts()
            } else {
              this.snackbarText = "Comercio no encontrado.";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = "Comercio no encontrado.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el ID del comercio";
        this.snackbar = true;
      }
    },
    loadProducts(){
      this.products=[]
                db
                .collection(`products`)
                .where("deleted", "==", false)
                .where(
                  "business",
                  "array-contains",
                  this.businessId
                ).get()
      .then(snapshot => {
        if (snapshot.empty) {
          // alert('No hay productos para eliminar');
          this.snackbarText = "No hay productos para eliminar.";
          this.snackbar = true;
          return;
        }

        //ciclo para recorrer los productos y marcarlos como eliminados
        snapshot.forEach(doc => {
          //eslint-disable-next-line no-console
          //console.log(doc.id, '=>', doc.data());
          this.dialog = true;
          this.products.push( { id:doc.id,data: doc.data() } );
        });
      })
      .catch(error => {
        //eslint-disable-next-line no-console
        // console.error('Error getting documents: ', error);
        this.snackbarText = "Error getting documents: "+error;
        this.snackbar = true;
      });
    },
    confirmDelete(){
      this.dialog = false;
      this.saving = true;
      this.products.forEach( item => {
        db.collection("products")
        .doc(item.id)
        .update({
          deleted: true,
          deletedAt: new Date(),            
          active: false, 
          deletedBy: this.$store.state.user[".key"]
        })
        .then(() => {
          this.saving = false;
          this.snackbarText = "Productos eliminados correctamente.";
          this.snackbar = true;
          this.loadProducts();
        })
        .catch((error) => {
          this.saving = false;
          this.snackbarText = "Error al eliminar productos: "+error;
          this.snackbar = true;
        });
      });

      //eliminar categorias
      db.collection(`businesses/${this.businessId}/menu`)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            deleted: true,
            deletedAt: new Date(),            
            active: false, 
            deletedBy: this.$store.state.user[".key"]
          });
        });
      });
 
    }
  },
  mounted() {
    this.$store.state.visibleSearch = false;
    this.loading = false;
  }
};
</script>


<style scoped>
.input {
  display: none;
}

.file-name {
  font-weight: bold;
  font-size: 15px;
  color: #f06a25;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}
</style>